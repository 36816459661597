export default defineNuxtRouteMiddleware((to) => {
  const config = useRuntimeConfig();
  if (!config.public.devDomain && to.path === "/devDomain") {
    return navigateTo("/");
  } else if (
    config.public.devDomain &&
    to.path !== "/devDomain" &&
    to.path !== "/noscript" &&
    to.path !== "/notSupportedBrowser" &&
    to.path !== "/mock-services/api/saveApplicationMock"
  ) {
    const devDomainCookie = useCookie("dev-domain");
    if (!devDomainCookie.value) {
      return navigateTo("/devDomain");
    }
  }
});
