<template>
  <div
    v-if="visible"
    :id="id"
    ref="tooltip"
    role="tooltip"
    class="group z-30 max-w-fit sm:max-w-sm whitespace-normal rounded border border-secondary-7 contrast:border-base-1 bg-secondary-9 contrast:bg-c-secondary-0 p-2 text-sm text-neutral-0 opacity-0 shadow transition-opacity duration-300 data-[visible='true']:opacity-100 absolute"
    :data-visible="visibleFinished"
  >
    <slot></slot>
    <div
      data-popper-arrow
      class="absolute before:absolute before:h-3 before:w-3 before:transform before:border-b before:border-r before:border-secondary-7 contrast:before:border-base-1 before:bg-secondary-9 contrast:before:bg-c-secondary-0 before:content-[''] group-data-[popper-placement^='bottom']:-top-1.5 group-data-[popper-placement^='left']:right-1.5 group-data-[popper-placement^='right']:-left-1.5 group-data-[popper-placement^='top']:bottom-1.5 group-data-[popper-placement^='bottom']:before:-right-1.5 group-data-[popper-placement^='left']:before:-top-1.5 group-data-[popper-placement^='right']:before:-bottom-1.5 group-data-[popper-placement^='top']:before:-left-1.5 group-data-[popper-placement^='bottom']:before:rotate-[225deg] group-data-[popper-placement^='left']:before:rotate-[-45deg] group-data-[popper-placement^='right']:before:rotate-[-225deg] group-data-[popper-placement^='top']:before:rotate-45"
    ></div>
  </div>
</template>

<script setup lang="ts">
import {
  createPopper,
  flip,
  type Instance,
  offset,
  preventOverflow,
} from "@popperjs/core";
import { onClickOutside, useIntersectionObserver } from "@vueuse/core";
import { onUnmounted, ref, toRef, nextTick } from "vue";

interface Props {
  id: string;
  targetElement: HTMLElement | undefined;
  intersectionObserver?: HTMLElement;
}

const props = defineProps<Props>();

const visible = ref<boolean>(false);
const visibleFinished = ref<boolean>(false);
const tooltip = ref<HTMLElement>();
const targetElementRef = toRef(props, "targetElement");
const targetVisible = ref(false);

onClickOutside(props.targetElement, destroyTooltip);

useIntersectionObserver(
  targetElementRef,
  async ([{ isIntersecting }]) => {
    if (isIntersecting) {
      targetVisible.value = true;
      if (props.targetElement) {
        props.targetElement.addEventListener("click", (e) => {
          e.stopPropagation();
          initializeTooltip(false);
        });
        props.targetElement.addEventListener(
          "mouseenter",
          initializeTooltipWithDelay,
        );
        props.targetElement.addEventListener("mouseleave", destroyTooltip);
      }
    } else {
      targetVisible.value = false;
      if (props.targetElement) {
        props.targetElement.removeEventListener(
          "mouseenter",
          initializeTooltipWithDelay,
        );
        props.targetElement.removeEventListener("mouseleave", destroyTooltip);
      }
      destroyTooltip();
    }
  },
  {
    root: props.intersectionObserver,
  },
);

let popper = ref<Instance>();
async function initializeTooltip(useDelay: boolean) {
  visible.value = true;
  visibleFinished.value = false;
  if (useDelay) {
    setTimeout(() => (visibleFinished.value = true), 300);
    await nextTick();
  } else {
    visibleFinished.value = true;
  }
  if (props.targetElement && tooltip.value) {
    popper.value = createPopper(props.targetElement, tooltip.value, {
      placement: "auto",
      modifiers: [
        preventOverflow,
        flip,
        {
          ...offset,
          options: {
            offset: () => [0, 8],
          },
        },
      ],
    });

    props.targetElement.setAttribute("aria-describedby", props.id);
    popper.value.update();
  }
}

function destroyTooltip() {
  if (props.targetElement) {
    props.targetElement.removeAttribute("aria-describedby");
  }
  if (popper.value) {
    popper.value.destroy();
  }
  visible.value = false;
}

onUnmounted(() => {
  destroyTooltip();
  if (props.targetElement) {
    props.targetElement.removeEventListener(
      "mouseenter",
      initializeTooltipWithDelay,
    );
    props.targetElement.removeEventListener("mouseleave", destroyTooltip);
  }
});

function initializeTooltipWithDelay() {
  initializeTooltip(true);
}
</script>
