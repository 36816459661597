import { default as declarationpe6ay2YCXVMeta } from "/var/lib/jenkins/workspace/PZO_master/client/switcher-public/pages/declaration.vue?macro=true";
import { default as devDomainoUHBqTz1M3Meta } from "/var/lib/jenkins/workspace/PZO_master/client/common-components-nuxt/pages/devDomain.vue?macro=true";
import { default as indexyg6c0s7JyNMeta } from "/var/lib/jenkins/workspace/PZO_master/client/switcher-public/pages/index.vue?macro=true";
import { default as notSupportedBrowserCyAG4i2ZVbMeta } from "/var/lib/jenkins/workspace/PZO_master/client/common-components-nuxt/pages/notSupportedBrowser.vue?macro=true";
export default [
  {
    name: "declaration",
    path: "/declaration",
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/switcher-public/pages/declaration.vue")
  },
  {
    name: "devDomain",
    path: "/devDomain",
    meta: devDomainoUHBqTz1M3Meta || {},
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/common-components-nuxt/pages/devDomain.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/switcher-public/pages/index.vue")
  },
  {
    name: "notSupportedBrowser",
    path: "/notSupportedBrowser",
    meta: notSupportedBrowserCyAG4i2ZVbMeta || {},
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/common-components-nuxt/pages/notSupportedBrowser.vue")
  }
]