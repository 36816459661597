import { addHeader } from "common-components-nuxt/server/utils/headers";
import { sendRedirect } from "h3";

export default defineNuxtPlugin(() => {
  const requestHeaders = useRequestHeaders([
    "cookie",
    "x-forwarded-host",
    "x-forwarded-for",
    "user-agent",
  ]);
  const event = useRequestEvent();
  const config = useRuntimeConfig();

  const api = $fetch.create({
    onRequest({ options }) {
      if (!options?.retry) {
        options.retry = 0;
      }
      let headers: HeadersInit = {};
      if (options.headers) {
        headers = options.headers;
      }
      addHeader(headers, "x-csrf-protection", "1");
      if (requestHeaders["x-forwarded-host"]) {
        addHeader(
          headers,
          "x-forwarded-host",
          requestHeaders["x-forwarded-host"],
        );
      }
      if (requestHeaders["x-forwarded-for"]) {
        addHeader(
          headers,
          "x-forwarded-for",
          requestHeaders["x-forwarded-for"],
        );
      }
      if (requestHeaders.cookie) {
        addHeader(headers, "cookie", requestHeaders.cookie);
      }
      if (requestHeaders["user-agent"]) {
        addHeader(headers, "user-agent", requestHeaders["user-agent"]);
      }
    },
    onResponseError({ response }) {
      const errorData = response._data?.data ?? response._data;
      if (errorData && errorData.code === "unauthorized") {
        clearNuxtData((key) => key.startsWith("/api/user"));
        clearNuxtState();
        if (import.meta.server && event) {
          sendRedirect(event, `${config.app.baseURL}/unauthorized`);
        } else {
          reloadNuxtApp({
            path: `${config.app.baseURL}/unauthorized`,
            force: true,
            persistState: false,
          });
        }
        return;
      }
      if (errorData && errorData.code === "session_timeout") {
        clearNuxtData((key) => key.startsWith("/api/user"));
        clearNuxtState();
        if (import.meta.server && event) {
          sendRedirect(event, `${config.app.baseURL}/sessionTimeout`);
        } else {
          reloadNuxtApp({
            path: `${config.app.baseURL}/sessionTimeout`,
            force: true,
            persistState: false,
          });
        }
        return;
      }
      let message = response._data?.message ?? response._data;
      if (response.url) {
        message += ` [${response.url}]`;
      }
      message += ` [${event?.node.req.url}]`;
      showError({
        fatal: true,
        message: message,
        statusCode: response.status,
        statusMessage: response._data?.statusMessage,
        data: errorData,
        stack: response._data?.stack,
      });
    },
  });

  return {
    provide: {
      api,
    },
  };
});
