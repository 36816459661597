<template>
  <div class="relative flex justify-center">
    <hr
      class="absolute inset-x-0 top-1/2 z-0 text-neutral-7 contrast:text-base-1"
    />
    <span
      class="z-10 bg-base-2 px-2 text-sm font-medium uppercase text-neutral-4"
    >
      <slot></slot>
    </span>
  </div>
</template>
