import { legacyApiFetch } from "../utils/legacyApiFetch";
import { matchesUA } from "browserslist-useragent";
export default defineNuxtRouteMiddleware((to) => {
  if (to.path === "/notSupportedBrowser") {
    return undefined;
  }

  const browserSupported = useSessionStorage("browserSupported", 0);
  if (browserSupported.value !== 0) {
    return browserSupported.value === 2
      ? navigateTo("/notSupportedBrowser")
      : undefined;
  }

  if (!isBrowserSupported()) {
    browserSupported.value = 2;
    callApiBrowserNotSupported();
    return navigateTo("/notSupportedBrowser");
  }

  if (import.meta.client) {
    browserSupported.value = 1;
  }

  return undefined;
});

function callApiBrowserNotSupported() {
  if (import.meta.client) {
    try {
      legacyApiFetch(
        "api/browserNotSupported",
        function (errorCode: number | null) {
          if (errorCode) {
            console.error("browserNotSupported API call failed: ", errorCode);
          }
        },
      );
    } catch (error) {
      console.error("browserNotSupported API call failed: ", error);
    }
  } else {
    useApi("/api/browserNotSupported");
  }
}

function isBrowserSupported(): boolean {
  if (!import.meta.client && !isSupportingBrowserVersion()) {
    return false;
  }

  if (import.meta.client && !testAllFeatures()) {
    return false;
  }
  return true;
}

function isSupportingBrowserVersion(): boolean {
  const headers = useRequestHeaders(["user-agent"]);
  const userAgentString = headers["user-agent"];
  if (userAgentString) {
    return matchesUA(userAgentString, {
      browsers: ["since 2018", "not dead", "unreleased versions"],
      allowHigherVersions: true,
    });
  }
  return false;
}
