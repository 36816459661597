import {
  isRegisteredError,
  isNuxtErrorCheck,
} from "common-components-nuxt/server/utils/error";

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const chunkErrors = new Set();

  router.beforeEach(() => {
    chunkErrors.clear();
  });

  nuxtApp.hook("app:chunkError", ({ error }) => {
    chunkErrors.add(error);
  });

  nuxtApp.vueApp.config.errorHandler = async (error) => {
    if (
      !chunkErrors.has(error) &&
      isNuxtErrorCheck(error) &&
      !isRegisteredError(error)
    ) {
      error.fatal = true;
      showError(error);
    }
  };
});
