export function testAllFeatures() {
  return (
    isCustomElementsSupported() &&
    isWebSocketsSupported() &&
    isCSSAppearanceSupported() &&
    isCSSGridSupported() &&
    isCSSFiltersSupported() &&
    isCSSFlexboxSupported() &&
    isCSSFontFaceSupported() &&
    isHTMLTemplateSupported() &&
    isES6ArraySupported() &&
    isES6ArrowSupported() &&
    isES6CollectionsSupported() &&
    isES6ContainsSupported() &&
    isES6NumberSupported() &&
    isES6ObjectSupported() &&
    isES6PromisesSupported() &&
    isES6TemplateStringsSupported() &&
    isNetworkFetchSupported()
  );
}

function isCustomElementsSupported() {
  return "customElements" in window;
}

function isWebSocketsSupported() {
  return "WebSocket" in window || "MozWebSocket" in window;
}

function isCSSAppearanceSupported() {
  const testElement = document.createElement("div");
  return (
    "appearance" in testElement.style || "webkitAppearance" in testElement.style
  );
}

function isCSSGridSupported() {
  const testElement = document.createElement("div");
  return "grid" in testElement.style || "msGrid" in testElement.style;
}

function isCSSFiltersSupported() {
  const testElement = document.createElement("div");
  return "filter" in testElement.style;
}

function isCSSFlexboxSupported() {
  const testElement = document.createElement("div");
  return "flex" in testElement.style || "webkitFlex" in testElement.style;
}

function isCSSFontFaceSupported() {
  return "FontFace" in window;
}

function isHTMLTemplateSupported() {
  return "content" in document.createElement("template");
}

function isES6ArraySupported() {
  return "from" in Array && "of" in Array;
}

function isES6ArrowSupported() {
  try {
    eval("let test = () => {}");
    return true;
  } catch (error) {
    return false;
  }
}

function isES6CollectionsSupported() {
  return (
    "Map" in window &&
    "Set" in window &&
    "WeakMap" in window &&
    "WeakSet" in window
  );
}

function isES6ContainsSupported() {
  return "includes" in String.prototype;
}

function isES6NumberSupported() {
  return "isNaN" in Number && "isFinite" in Number;
}

function isES6ObjectSupported() {
  return "assign" in Object && "is" in Object && "setPrototypeOf" in Object;
}

function isES6PromisesSupported() {
  return "Promise" in window && "finally" in Promise.prototype;
}

function isES6TemplateStringsSupported() {
  try {
    // Test template string syntax
    eval("let test = `template string`");
    return true;
  } catch (error) {
    return false;
  }
}

function isNetworkFetchSupported() {
  return "fetch" in window;
}
