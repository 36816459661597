export function legacyApiFetch(
  url: string,
  callback: (err: number | null) => void,
): void {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        callback(null);
      } else {
        callback(xhr.status);
      }
    }
  };
  xhr.send(null);
}
